<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="4"
        ><el-input
          size="small"
          v-model="queryFilter.keyWorlds"
          placeholder="请输入采购订单相关信息进行筛选"
          clearable
      /></el-col>
      <el-col :span="4">
        <el-select
          size="small"
          v-model="queryFilter.status"
          clearable
          placeholder="筛选采购状态"
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          size="small"
          v-model="queryFilter.supplierId"
          clearable
          placeholder="筛选供应商采购订单"
        >
          <el-option
            v-for="item in supplierOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="7">
        <el-date-picker
          size="small"
          v-model="timer"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-col>
      <el-button size="small" type="primary" @click="flashTable"
        >查询订单</el-button
      >
      <el-button
        size="small"
        type="primary"
        style="float: right"
        @click="pushAddPage"
        >新增订单</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        stripe
        row-key="id"
        style="width: 100%"
        border
        @expand-change="expandChange"
        :expand-row-keys="expandRowIds"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand_box">
              <el-table
                ref="productRef"
                :data="scope.row.detail"
                :summary-method="getSummaries"
                :show-summary="true"
                v-loading="scope.row.loading"
                border
              >
                <el-table-column
                  type="index"
                  width="80"
                  label="#"
                ></el-table-column>
                <el-table-column
                  prop="productName"
                  show-overflow-tooltip
                  label="商品名称"
                >
                </el-table-column>
                <el-table-column prop="productAmount" label="商品数量">
                </el-table-column>
                <el-table-column prop="productPrice" label="商品价格">
                </el-table-column>
                <el-table-column label="型号">
                  <template slot-scope="scope">
                    {{ scope.row.modelName || scope.row.productModel || '无' }}
                  </template>
                </el-table-column>
                <el-table-column label="规格">
                  <template slot-scope="scope">
                    {{ scope.row.productSpecs || '无' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="remark"
                  show-overflow-tooltip
                  label="备注"
                >
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column width="240" label="订单编号">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              @click="handleCopy(scope.row.number, $event)"
              >{{ scope.row.number }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          label="供应商名称"
          prop="supplierName"
        ></el-table-column>
        <el-table-column label="采购状态">
          <template slot-scope="scope">
            <el-tag :type="tagType(scope.row.procurementState)" effect="dark">
              {{ scope.row.procurementStateDisplayName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="采购进度">
          <template slot-scope="scope">
            <el-tag type="info" effect="dark" v-if="!scope.row.isCheck">
              采购方处理中
            </el-tag>
            <el-tag type="success" effect="dark" v-else> 供应商处理中 </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="订单创建时间">
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column width="300" fixed="right" label="编辑">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="openInStorageDialog(scope.row)"
              type="success"
              icon="el-icon-message"
              >详情</el-button
            >
            <el-button
              size="mini"
              :disabled="
                scope.row.procurementState != 1 &&
                scope.row.procurementState != 4
              "
              @click="eidtInStorage(scope.row)"
              type="primary"
              icon="el-icon-edit"
              >编辑</el-button
            >
            <el-button
              :disabled="
                scope.row.procurementState != 1 &&
                scope.row.procurementState != 4
              "
              size="mini"
              @click="deleteInStorageHandle(scope.row)"
              type="danger"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <!-- 采购订单详情 -->
    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="procurementDialogVisble"
    >
      <!-- 入库单详情 -->
      <el-row>
        <el-col :span="8">采购单号:{{ procurementData.number }}</el-col>
        <el-col :span="8"
          >采购单状态:{{ procurementData.procurementStateDisplayName }}</el-col
        >
        <el-col :span="8">制单人:{{ procurementData.createUserName }}</el-col>
        <el-col :span="16">备注:{{ procurementData.remark }}</el-col>
      </el-row>

      <!-- 入库单商品详情 -->
      <el-table :data="procurementData.detail" border>
        <el-table-column
          prop="productName"
          show-overflow-tooltip
          label="商品名称"
        >
        </el-table-column>
        <el-table-column prop="productAmount" label="商品数量">
        </el-table-column>
        <el-table-column prop="productPrice" label="商品价格">
        </el-table-column>
        <el-table-column label="型号">
          <template slot-scope="scope">
            {{ scope.row.modelName || scope.row.productModel || '无' }}
          </template>
        </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpecs || '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" show-overflow-tooltip label="商品备注">
        </el-table-column>
      </el-table>

      <!-- 审核进度 -->
      <el-row style="margin-top: 20px">
        <el-row>
          <span>审核进度</span>
          <el-button
            type="success"
            :disabled="canAudit"
            style="float: right"
            size="mini"
            @click="upAuditeClick"
            >提交审核</el-button
          >
        </el-row>
        <el-card
          v-if="procurementData.procurementState != 1"
          style="margin-top: 20px"
        >
          <template v-if="procurementData.procurementState == 2">
            <el-tag type="info">等待审核中</el-tag>
            <span style="float: right">
              <el-button
                type="danger"
                size="mini"
                @click="openAuditCheckDialog(false)"
                >不通过审核</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="openAuditCheckDialog(true)"
                >通过审核</el-button
              >
            </span>
          </template>
          <el-tag
            v-else-if="
              procurementData.procurementState == 3 ||
              procurementData.procurementState == 5 ||
              procurementData.procurementState == 6
            "
            type="success"
            >审核成功</el-tag
          >
          <el-tag v-else type="danger">审核失败</el-tag>
          <el-row type="flex" style="margin-top: 20px">
            <span style="padding: 10px; color: red">{{
              procurementData.errorMsg
            }}</span>
          </el-row>
        </el-card>
      </el-row>
      <!-- 收货进度 -->
      <el-row style="margin-top: 20px">
        <el-row
          ><span>快递进度:</span>
          <el-button
            type="success"
            :disabled="canGoods"
            style="float: right"
            size="mini"
            @click="openInGoodsDialog"
            >查看订单发货比例</el-button
          ></el-row
        >

        <div
          v-for="(item, index) in procurementData.deliveryGoodsList"
          :key="'inGood' + index"
        >
          <span>快递编号:</span
          ><el-link
            type="primary"
            @click="openInGoodsDetialDialog(item.details)"
            >快递单号:{{ item.courierNumber }} 出库时间:{{
              item.createTime
            }}</el-link
          >
        </div>
      </el-row>

      <el-row>
        <el-button
          type="success"
          size="mini"
          @click="downLoadExcel(procurementData)"
          >下载订单报表</el-button
        >
      </el-row>

      <!-- 审核建议对话框 -->
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="aduitCheckDialog"
      >
        <el-form>
          <el-form-item label="审核意见:">
            <el-input
              type="text"
              v-model="auditForm.remark"
              placeholder="请输入审核意见"
            ></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="aduitCheckDialog = false"
            >取消</el-button
          >
          <el-button type="primary" @click="aduitCheckCheck">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 入库单 -->
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="deliveryDetialDialog"
      >
        <el-row>
          <el-table
            :data="deliveryDetialData"
            stripe
            style="width: 100%"
            border
            :key="tablekey"
          >
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="商品型号" prop="modelName">
            </el-table-column>
            <el-table-column label="商品规格" prop="productSpecs">
            </el-table-column>
            <el-table-column label="商品总数" prop="productAmount">
            </el-table-column>
            <el-table-column label="已发货数量" prop="sendAmount">
            </el-table-column>
            <el-table-column label="剩余待发货数量">
              <template slot-scope="scope">
                {{ scope.row.productAmount - scope.row.sendAmount }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="deliveryDetialDialog = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 入库单详情 -->
      <!-- 订单快递详情 -->
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="inGoodsDetialDialog"
      >
        <el-row>
          <el-table :data="inGoodsDetialData" stripe style="width: 100%" border>
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="商品型号" prop="modelName">
            </el-table-column>
            <el-table-column label="商品规格" prop="productSpecs">
            </el-table-column>
            <el-table-column label="发货数量" prop="amount"> </el-table-column>
          </el-table>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="inGoodsDetialDialog = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-dialog>
  </el-card>
</template>
<script>
import clip from '@/utils/clipboard'
import { timeOffset } from '@/utils/zoneTime.js'
import {
  getProcurementList,
  getProcurementState,
  getProcurementInfo,
  upProcurementAudite,
  deleteProcurement,
  auditProcurement,
  downLoadAdminProcurement,
} from '@/api/procurement.js'
import { getAll } from '@/api/supplier.js'
export default {
  data() {
    return {
      supplierOptions: [],
      timer: '',
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        supplierId: null,
        status: null,
      },
      expandRowIds: [],
      tableData: [],
      stateOptions: [],
      // 入库订单详情对话框显示隐藏
      procurementDialogVisble: false,
      procurementData: {},
      // 审核对话框建议内容
      aduitCheckDialog: false,
      auditForm: {
        isCheck: false,
        remark: '',
      },
      // 发货订单的数据
      deliveryDetialData: [],
      deliveryDetialDialog: false,
      tablekey: false,
      inGoodsDetialDialog: false,
      inGoodsDetialData: [],
    }
  },
  created() {
    this.getTableData()
    this.getOptions()
    this.getSupplierOptions()
  },
  methods: {
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    async getOptions() {
      let { data } = await getProcurementState()
      this.stateOptions = data
    },
    async getSupplierOptions() {
      let { data } = await getAll()
      this.supplierOptions = data
    },
    async getTableData() {
      let filterData = { ...this.queryFilter }

      if (this.timer) {
        filterData.startTime = timeOffset(this.timer[0])
        filterData.endTime = timeOffset(this.timer[1])
      }

      let { data } = await getProcurementList(filterData)

      this.tableData = data.data.map((item) => {
        item.loading = false
        item.detail = null
        return item
      })
      this.queryFilter.total = data.total
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    handleSizeChange(val) {
      this.queryFilter.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    pushAddPage() {
      this.$router.push({ path: '/AddProcurement' })
    },
    async expandChange(row, expandedRows) {
      if (!row.productList) {
        row.loading = true
        try {
          let data = await getProcurementInfo(row.id)

          this.tableData.forEach((item, index, arr) => {
            if (item.id == row.id) {
              arr[index].detail = data.data.detail
            }
          })

          row.loading = false
        } catch (error) {
          row.loading = false
        }
      }
    },
    // 打开入库单详情显示页面
    async openInStorageDialog(row) {
      let data = await getProcurementInfo(row.id)
      this.procurementData = data.data
      this.procurementDialogVisble = true
    },
    // 获取时间线对应的图标颜色
    getTimeTimeLineColor(log) {
      if (log.auditState == 0) {
        return '#909399'
      }

      if (log.auditState == 1) {
        return '#5CB87A'
      }

      if (log.auditState == 2) {
        return '#F56C6C'
      }

      return '#909399'
    },
    openAuditCheckDialog(isCheck) {
      this.auditForm.isCheck = isCheck
      this.aduitCheckDialog = true
    },
    // 提交审核申请
    async upAuditeClick() {
      await upProcurementAudite(this.procurementData.id)

      let { data } = await getProcurementInfo(this.procurementData.id)

      this.procurementData = data

      this.getTableData()
    },
    // 提交审核意见
    async aduitCheckCheck() {
      if (this.auditForm.isCheck == false && this.auditForm.remark == '') {
        return this.$message.error('请输入不予通过的理由')
      }

      await auditProcurement(this.procurementData.id, this.auditForm)

      let { data } = await getProcurementInfo(this.procurementData.id)
      this.procurementData = data

      this.getTableData()

      this.aduitCheckDialog = false
    },
    // 打开收货对话框
    openInGoodsDialog() {
      let productInGoods =
        this.procurementData.deliveryGoodsList?.reduce(function (pre, cur) {
          return [...pre, ...cur.details]
        }, []) ?? []

      let productList = [...this.procurementData.detail].map((item) => {
        item['sendAmount'] = 0
        item['count'] = item.productAmount
        return item
      })

      productInGoods.forEach((item) => {
        let filterItme = productList.filter((pItem) => {
          return (
            pItem.productId == item.productId &&
            pItem.modelName == item.modelName &&
            pItem.productSpecs == item.productSpecs
          )
        })

        // console.log(filterItme)

        if (filterItme && filterItme.length > 0) {
          filterItme[0].sendAmount = parseInt(
            Number(filterItme[0].sendAmount) + Number(item.amount)
          )

          filterItme[0].count = parseInt(
            parseInt(filterItme[0].productAmount) -
              parseInt(filterItme[0].sendAmount)
          )
        }
      })

      this.deliveryDetialData = productList
      this.deliveryDetialDialog = true
    },
    // 打开入库详情对话框
    openInGoodsDetialDialog(data) {
      this.inGoodsDetialData = data
      this.inGoodsDetialDialog = true
    },
    eidtInStorage(row) {
      this.$router.push({ path: '/EditProcurement/' + row.id })
    },
    // 表格尾行合计行
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // 第一列索引
        if (index === 0) {
          sums[index] = '总价'
          return
        }

        // 第二列商品名称
        if (index === 2) {
          if (!data) {
            sums[index] = 'N/A'
          } else {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ' 件'
            } else {
              sums[index] = 'N/A'
            }
          }
          return
        }

        // 第四列总价
        if (index === 3) {
          if (!data) {
            sums[index] = 'N/A'
          } else {
            const priceArr = data.map((item) => {
              return Number(item['productPrice'] * item['productAmount'])
            })

            if (!priceArr.every((value) => isNaN(value))) {
              sums[index] = priceArr
                .reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
                .toFixed(2)
              sums[index] += ' 元'
            } else {
              sums[index] = 'N/A'
            }
          }
          return
        }

        sums[index] = ''
      })

      this.$nextTick((_) => {
        this.$refs['productRef'].doLayout()
      })

      return sums
    },

    deleteInStorageHandle(row) {
      this.$confirm('此操作将删除当前采购单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteProcurement(row.id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getTableData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
    },
    tagType(val) {
      if (val == 4) {
        return 'danger'
      }

      if (val == 2) {
        return 'warning'
      }

      if (val == 1) {
        return 'info'
      }

      return 'success'
    },
    async downLoadExcel(row) {
      await downLoadAdminProcurement(row.id)
    },
  },
  computed: {
    canAudit() {
      return (
        this.procurementData.procurementState != 1 &&
        this.procurementData.procurementState != 4
      )
    },
    canGoods() {
      return (
        // 待发货状态
        this.procurementData.procurementState != 3 &&
        // 部分发货状态
        this.procurementData.procurementState != 5
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.expand_box {
  padding: 10px 20px;
  box-sizing: border-box;
}
</style>
